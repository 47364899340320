import React from "react";
import { BrowserRouter } from "react-router-dom";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import Router from "./Router";
import ErrorBoundary from "./components/errorBoundary/ErrorBoundary";
import { AuthProvider } from "./context/authContext";
import { SplitPaneProvider } from "./context/splitPaneContext";

import { NAVBAR_BLUE } from "./utils/constants";

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: NAVBAR_BLUE,
    },
  },
});

function App() {
  return (
    <>
      <AuthProvider>
        <SplitPaneProvider>
          <BrowserRouter>
            <ErrorBoundary>
              <MuiThemeProvider theme={theme}>
                <Router />
              </MuiThemeProvider>
            </ErrorBoundary>
          </BrowserRouter>
        </SplitPaneProvider>
      </AuthProvider>
    </>
  );
}

export default App;
