const API_HOST = process.env.REACT_APP_API_HOST;
const HOME = process.env.REACT_APP_HOME;
const API_HOST_IMAGE = process.env.REACT_APP_API_HOST_IMAGE;

export { API_HOST };
export { API_HOST_IMAGE };
export { HOME };
export const pathIDSalt = 8329;
export const WHITE = "#FFFFFF";
export const BLACK = "#000000";
export const BACKGROUND_GREY = "#F7F7F7";
export const NAVBAR_BLUE = "#007BFF";
export const BORDER_GREY = "#E8E9EC";
export const TEXT_GREY = "#4D4F5C90";
export const BULLET_GREY = "#EDECFE";
export const HIGHLIGHT_BLUE = "#80bdff";

export const RED = "#df426b";
export const GREEN = "#4caf50";
export const ORANGE = "#ffc107";

export const INPUT_BOX_RADIUS = "15px";
export const INPUT_BOX_BACKGROUND = "white";
export const BUTTON_BORDER_RADIUS = "5px";
export const INPUT_BOX_PADDING = "15px 5px 15px 5px";
export const BOX_SHADOW = "5px 4px 30px rgba(0,0,0,.10)";
export const INPUT_BOX_SHADOW = "0 0 0 0.2rem rgb(0 123 255 / 25%)";
export const ADDED_INPUT_BOX_BORDER = "3px solid #619ce8";
export const ADDED_INPUT_BOX_SHADOW = "rgba(97,156,232, 0.35) 0px 10px 20px";
export const DELETED_INPUT_BOX_BORDER = "3px solid red";
export const DELETED_INPUT_BOX_SHADOW = "rgba(238,110,115,0.35) 0px 10px 20px";

export const deletedRowStyle = {
  position: "relative",
  borderRadius: INPUT_BOX_RADIUS,
  boxShadow: DELETED_INPUT_BOX_SHADOW,
  padding: INPUT_BOX_PADDING,
  background: INPUT_BOX_BACKGROUND,
  border: DELETED_INPUT_BOX_BORDER,
  marginBottom: "15px",
};
export const addedRowStyle = {
  position: "relative",
  borderRadius: INPUT_BOX_RADIUS,
  boxShadow: ADDED_INPUT_BOX_SHADOW,
  padding: INPUT_BOX_PADDING,
  background: INPUT_BOX_BACKGROUND,
  border: ADDED_INPUT_BOX_BORDER,
  marginBottom: "15px",
};

export const deletedRowWithoutShadowStyle = {
  ...deletedRowStyle,
  boxShadow: "",
};
export const addedRowWithoutShadowStyle = {
  ...addedRowStyle,
  boxShadow: "",
};

export const INITIAL_STATE = "INITIAL_STATE";
export const ADD = "ADD";
export const ADD_END = "ADD_END";
export const DELETE = "DELETE";
export const UPDATE_CONSOLIDATED_BILL = "UPDATE_CONSOLIDATED_BILL";
export const UPDATE_DETAILED_BILL = "UPDATE_DETAILED_BILL";
export const UPDATE_NAME = "UPDATE_NAME";
export const UPDATE_CATEGORY_1 = "UPDATE_CATEGORY_1";
export const UPDATE_DISCHARGE_SUMMARY = "UPDATE_DISCHARGE_SUMMARY";
export const UPDATE_REIMBURSEMENT_CLAIM_FORM =
  "UPDATE_REIMBURSEMENT_CLAIM_FORM";
export const UPDATE_PREAUTH_CLAIM_FORM = "UPDATE_PREAUTH_CLAIM_FORM";
