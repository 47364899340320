/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef, useState } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useExpanded,
} from "react-table";

import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";

const Table = forwardRef(
  ({ renderRowSubComponent, data, columns }, currentTable) => {
    const [filterInput, setFilterInput] = useState("");

    const hiddenColumns = [
      "rowID",
      "rowIDWithoutSalt",
      "assignedToID",
      "assignedToName",
    ];
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser !== "superuser") {
      hiddenColumns.push("assign");
    }

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      // pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize },
      // setFilter,
      setGlobalFilter,
      visibleColumns,
    } = useTable(
      {
        columns,
        data,
        initialState: {
          pageSize: 10,
          pageIndex: 0,
          hiddenColumns,
        },
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      useExpanded,
      usePagination
    );
    const handleFilterChange = useAsyncDebounce((searchFilterInput) => {
      setGlobalFilter(searchFilterInput || undefined);
    }, 200);

    const handleCurrentPageChange = (event) => {
      const currentPageNumber = event.target.value
        ? Number(event.target.value) - 1
        : 0;
      gotoPage(currentPageNumber);
    };

    return (
      <>
        <div className="d-flex justify-content-end mb-3">
          <span className="mx-4">
            Per Page:{" "}
            <select
              value={pageSize}
              onChange={(event) => {
                setPageSize(Number(event.target.value));
              }}
            >
              {/* Number of rows per page */}
              {[10, 20, 30, 40, 50].map((rowsPerPage) => (
                <option key={`rowsPerPage-${rowsPerPage}`} value={rowsPerPage}>
                  {rowsPerPage}
                </option>
              ))}
            </select>
          </span>
          <span className="mx-4">
            Search:{" "}
            <input
              value={filterInput || ""}
              onChange={(event) => {
                setFilterInput(event.target.value);
                handleFilterChange(event.target.value);
              }}
              placeholder={`${rows.length} records...`}
            />
          </span>
        </div>

        <table {...getTableProps()} ref={currentTable}>
          <thead>
            {headerGroups.map((headerGroup) => {
              const {
                key: columnHeaderGroupKey,
                ...restHeaderGroupProps
              } = headerGroup.getHeaderGroupProps();
              return (
                <tr
                  key={`table-header-${columnHeaderGroupKey}`}
                  {...restHeaderGroupProps}
                >
                  {headerGroup.headers.map((column) => {
                    const { key: columnHeaderKey } = column.getHeaderProps();
                    return (
                      <th
                        key={`table-column-${columnHeaderKey}`}
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        <div className="d-flex flex-row align-items-center justify-content-center">
                          <span> {column.render("Header")}</span>

                          {/* Add a sort direction indicator */}
                          {column.id !== "expander" ? (
                            <>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <ArrowDropDown />
                                ) : (
                                  <ArrowDropUp />
                                )
                              ) : (
                                <span className="d-flex flex-column">
                                  <ArrowDropUp />
                                  <ArrowDropDown />
                                </span>
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              const { key: rowKey, rowProps } = row.getRowProps();
              return (
                <React.Fragment key={`table-row-${rowKey}`}>
                  <tr {...rowProps}>
                    {row.cells.map((cell) => {
                      const { key: cellKey } = cell.getCellProps();
                      return (
                        <td
                          key={`table-cel-${cellKey}`}
                          className="cell"
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                  {row.isExpanded &&
                    renderRowSubComponent({ row, rowProps, visibleColumns })}
                </React.Fragment>
              );
            })}
            <tr role="row">
              <td colSpan="10">
                <div style={{ position: "relative" }}>
                  <button
                    type="button"
                    className={
                      canPreviousPage
                        ? "btn-pagination"
                        : "btn-pagination-disabled"
                    }
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  >
                    Previous
                  </button>
                  <span className="mx-5">
                    Page{" "}
                    <strong className="pagination-page-count">
                      {pageIndex + 1}
                    </strong>{" "}
                    <span> of </span>
                    <strong className="pagination-page-count">
                      {pageCount}{" "}
                    </strong>{" "}
                  </span>
                  <button
                    type="button"
                    className={
                      canNextPage ? "btn-pagination" : "btn-pagination-disabled"
                    }
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                  >
                    Next
                  </button>
                  <span style={{ position: "absolute", right: "50px" }}>
                    <span className="mx-2">|</span> Go to page:{" "}
                    <input
                      style={{ width: "100px" }}
                      className="pagination-page-count px-2"
                      type="number"
                      defaultValue={pageIndex + 1}
                      onFocus={(event) => event.target.select()}
                      onChange={handleCurrentPageChange}
                    />{" "}
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  }
);

export default Table;
