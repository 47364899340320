import React, {
  useContext,
  useRef,
  useEffect,
  createContext,
  useState,
  useCallback,
} from "react";

const splitPaneContext = createContext();

export function useSplitPane() {
  return useContext(splitPaneContext);
}

function useSplitPaneProvider() {
  const [clientWidth, setClientWidth] = useState(null);
  const xDividerPosition = useRef(null);

  const handleMouseDown = (event) => {
    xDividerPosition.current = event.clientX;
  };

  const onMouseHoldUp = () => {
    xDividerPosition.current = null;
  };

  /* this function is only called when the mouse is moved with the mouse button clicked */
  const onMouseHoldMove = useCallback(
    (event) => {
      if (!xDividerPosition.current) {
        return;
      }

      setClientWidth(clientWidth + event.clientX - xDividerPosition.current);
      xDividerPosition.current = event.clientX;
    },
    [clientWidth]
  );

  useEffect(() => {
    document.addEventListener("mouseup", onMouseHoldUp);
    document.addEventListener("mousemove", onMouseHoldMove);

    return () => {
      document.removeEventListener("mouseup", onMouseHoldUp);
      document.removeEventListener("mousemove", onMouseHoldMove);
    };
  });

  return {
    clientWidth,
    setClientWidth,
    handleMouseDown,
  };
}

export function SplitPaneProvider({ children }) {
  const splitPane = useSplitPaneProvider();
  return (
    <splitPaneContext.Provider value={splitPane}>
      {children}
    </splitPaneContext.Provider>
  );
}
