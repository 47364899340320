export default function exportTableToExcel(selectedTable, filename) {
  const dataType = "application/vnd.ms-excel";
  const tableHTML = selectedTable.outerHTML.replace(/ /g, "%20");
  /* Specify file name */
  const fileNameWithExt = filename ? `${filename}.xls` : "excel_data.xls";

  /* Create download link element */
  const downloadLink = document.createElement("a");

  document.body.appendChild(downloadLink);

  if (navigator.msSaveOrOpenBlob) {
    const blob = new Blob(["\ufeff", tableHTML], {
      type: dataType,
    });
    navigator.msSaveOrOpenBlob(blob, fileNameWithExt);
  } else {
    /* Create a link to the file */
    downloadLink.href = `data:${dataType}, ${tableHTML}`;

    /* Setting the file name */
    downloadLink.download = fileNameWithExt;

    /* triggering the function */
    downloadLink.click();
  }
}
