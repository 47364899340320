import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import callAPI from "../utils/callAPI";
import { useAuth } from "../context/authContext";
import useAsyncError from "../customHooks/asyncError";
import { pathIDSalt } from "../utils/constants";

export default function LoginTataAIG() {
  const history = useHistory();
  const loggedInUser = localStorage.getItem("user");
  const { token } = useParams();
  let { documentID } = useParams();
  const auth = useAuth();

  documentID *= pathIDSalt;

  const throwError = useAsyncError();
  const error = auth.status === 400;
  useEffect(() => {
    callAPI("GET", `?username=${token}`)
      .then((res) => {
        const userType = "staffuser";
        localStorage.setItem("user", userType);
        history.push(`/validate/${documentID}/Consolidated_Bill`);
      })
      .catch(() => {
        throwError(new Error(error));
      });
  }, [documentID, token]);

  useEffect(() => {
    if (loggedInUser === "undefined") {
      localStorage.clear();
    }
    if (loggedInUser !== null) {
      history.push(`/validate/${documentID}/Consolidated_Bill`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, loggedInUser]);

  return <>Loading....</>;
}
