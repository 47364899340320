import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";

import AccountCircle from "@material-ui/icons/AccountCircle";

import callAPI from "../../utils/callAPI";

import Sidebar from "../sidebar/Sidebar";

import logo from "../../assets/sherlock_logo.png";

import { NAVBAR_BLUE, WHITE } from "../../utils/constants";

export default function CustomNavbar() {
  const { pathname } = useLocation();
  const history = useHistory();
  let tabName;
  if (pathname === "/login") {
    tabName = "Login";
  } else if (pathname === "/") {
    tabName = "In-Progress Documents";
  } else if (pathname === "/exception") {
    tabName = "Claims in Exception";
  } else if (pathname === "/report") {
    tabName = "Claims Report";
  } else if (pathname === "/ocr_accuracy") {
    tabName = "OCR Accuracy";
  } else if (pathname === "/upload_documents") {
    tabName = "Upload Document";
  } else {
    tabName = "In-Progress Documents";
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const handleMouseOver = (event) => {
    const target = event.currentTarget;
    setAnchorEl(target);
  };
  const handleMouseOut = () => {
    setAnchorEl(null);
  };
  const handleClose = () => {
    callAPI("GET", "logout/", "")
      .then(() => {
        localStorage.clear();

        /* change expire attribute of cookies to current time, so it expires. 
        Note this doesn't work for http only cookies, so only csrf cookie will expire. sessionid is handled by django itself */
        document.cookie.split(";").forEach((cookie) => {
          document.cookie = cookie
            .replace(/^ +/, "")
            .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
        });
      })
      .then(() => {
        history.push("/login");
      });
  };
  return (
    <>
      <AppBar
        position="static"
        className="p-0 custom-navbar d-flex justify-content-center"
        style={{ backgroundColor: NAVBAR_BLUE }}
      >
        <Toolbar className="px-3">
          <Sidebar />
          <div className="mr-auto">
            <Typography disabled>{tabName}</Typography>
          </div>
          <div>
            <img
              style={{ verticalAlign: "top" }}
              src={logo}
              width="21"
              height="32"
              alt=""
            />
          </div>
          <Typography className="ml-2">Sherlock IPA</Typography>
          <div className="mx-2">
            <IconButton
              className="dropdown-trigger"
              data-target="account-dropdown"
              onMouseOver={handleMouseOver}
            >
              <AccountCircle style={{ color: WHITE, fontSize: "28px" }} />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              onMouseOut={handleMouseOut}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
}
