import React from "react";

export default function Card({
  heading,
  value,
  width,
  backgroundColor,
  color,
  image,
  imageSize,
}) {
  return (
    <>
      <div
        className="card"
        style={{
          width,
          backgroundColor,
          color,
          // borderTop: "3px solid #2dc7ff",
          // borderBottom: "3px solid #2dc7ff",
        }}
      >
        <div className="custom-card-header d-flex justify-content-center">
          {heading}
        </div>
        <div className="custom-card-body d-flex align-items-center justify-content-around">
          <img
            // style={{ verticalAlign: "top" }}
            alt=""
            width={imageSize}
            height={imageSize}
            src={image}
          />

          <div className="d-flex flex-column align-items-center">
            <span>{value}</span>
          </div>
        </div>
      </div>
    </>
  );
}
