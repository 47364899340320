import React from "react";
import useAsyncError from "../../customHooks/asyncError";
import callAPI from "../../utils/callAPI";

export default function DateFilter({
  toggleIsUpdating,
  api,
  data,
  setData,
  toggleFilterContainer,
}) {
  const throwError = useAsyncError();

  const months = [
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "june",
    "july",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec",
  ];

  const setFilterDates = (event) => {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;
    setData((prevState) => ({
      ...prevState,
      [fieldName]: fieldValue,
    }));
  };

  const selectMonth = (event) => {
    const selectedMonth = event.target.name;
    let firstDate;
    let lastDate;

    const convertDateToFieldValue = (dateobj) => {
      let day = `0${dateobj.getDate()}`;
      day = day.slice(-2);
      let month = `0${dateobj.getMonth() + 1}`;
      month = month.slice(-2);
      const date = `${dateobj.getFullYear()}-${month}-${day}`;
      return date;
    };

    const now = new Date();
    const today = convertDateToFieldValue(now);

    const currentmonth = now.getMonth() + 1;
    // const currentyear = now.getFullYear();
    const prevyear = now.getFullYear() - 1;

    if (selectedMonth === "qtdButton") {
      const quarter = Math.floor(now.getMonth() / 3);
      firstDate = new Date(now.getFullYear(), quarter * 3, 1);
      const quarterstart = convertDateToFieldValue(firstDate);
      setData((prevState) => ({ ...prevState, fromdate: quarterstart }));
    } else if (selectedMonth === "ytdButton") {
      firstDate = new Date(now.getFullYear(), 0, 1);
      const yearstart = convertDateToFieldValue(firstDate);
      setData((prevState) => ({ ...prevState, fromdate: yearstart }));
    } else if (selectedMonth === "jan") {
      const jan = Math.floor(now.getMonth() / 12);
      if (jan + 1 > currentmonth) {
        firstDate = new Date(prevyear, jan * 1, 1);
      } else {
        firstDate = new Date(now.getFullYear(), jan * 1, 1);
      }
      const janstart = convertDateToFieldValue(firstDate);
      setData((prevState) => ({ ...prevState, fromdate: janstart }));
      if (jan + 1 === currentmonth) {
        setData((prevState) => ({ ...prevState, todate: today }));
      } else {
        if (jan + 1 > currentmonth) {
          lastDate = new Date(prevyear, jan * 1 + 1, 0);
        } else {
          lastDate = new Date(now.getFullYear(), jan * 1 + 1, 0);
        }
        const janend = convertDateToFieldValue(lastDate);
        setData((prevState) => ({ ...prevState, todate: janend }));
      }
    } else if (selectedMonth === "feb") {
      const feb = Math.floor(now.getMonth() / 12 + 1);

      if (feb + 1 > currentmonth) {
        firstDate = new Date(prevyear, feb * 1, 1);
      } else {
        firstDate = new Date(now.getFullYear(), feb * 1, 1);
      }

      const febstart = convertDateToFieldValue(firstDate);
      setData((prevState) => ({ ...prevState, fromdate: febstart }));

      if (feb + 1 === currentmonth) {
        setData((prevState) => ({ ...prevState, todate: febstart }));
      } else {
        if (feb + 1 > currentmonth) {
          lastDate = new Date(prevyear, feb * 1 + 1, 0);
        } else {
          lastDate = new Date(now.getFullYear(), feb * 1 + 1, 0);
        }
        const febend = convertDateToFieldValue(lastDate);
        setData((prevState) => ({ ...prevState, todate: febend }));
      }
    } else if (selectedMonth === "mar") {
      const mar = Math.floor(now.getMonth() / 12 + 2);

      if (mar + 1 > currentmonth) {
        firstDate = new Date(prevyear, mar * 1, 1);
      } else {
        firstDate = new Date(now.getFullYear(), mar * 1, 1);
      }

      const marstart = convertDateToFieldValue(firstDate);
      setData((prevState) => ({ ...prevState, fromdate: marstart }));

      if (mar + 1 === currentmonth) {
        setData((prevState) => ({ ...prevState, todate: today }));
      } else {
        if (mar + 1 > currentmonth) {
          lastDate = new Date(prevyear, mar * 1 + 1, 0);
        } else {
          lastDate = new Date(now.getFullYear(), mar * 1 + 1, 0);
        }
        const marend = convertDateToFieldValue(lastDate);
        setData((prevState) => ({ ...prevState, todate: marend }));
      }
    } else if (selectedMonth === "apr") {
      const apr = Math.floor(now.getMonth() / 12 + 3);

      if (apr + 1 > currentmonth) {
        firstDate = new Date(prevyear, apr * 1, 1);
      } else {
        firstDate = new Date(now.getFullYear(), apr * 1, 1);
      }

      const aprstart = convertDateToFieldValue(firstDate);
      setData((prevState) => ({ ...prevState, fromdate: aprstart }));

      if (apr + 1 === currentmonth) {
        setData((prevState) => ({ ...prevState, todate: today }));
      } else {
        if (apr + 1 > currentmonth) {
          lastDate = new Date(prevyear, apr * 1 + 1, 0);
        } else {
          lastDate = new Date(now.getFullYear(), apr * 1 + 1, 0);
        }
        const aprend = convertDateToFieldValue(lastDate);
        setData((prevState) => ({ ...prevState, todate: aprend }));
      }
    } else if (selectedMonth === "may") {
      const may = Math.floor(now.getMonth() / 12 + 4);

      if (may + 1 > currentmonth) {
        firstDate = new Date(prevyear, may * 1, 1);
      } else {
        firstDate = new Date(now.getFullYear(), may * 1, 1);
      }

      const maystart = convertDateToFieldValue(firstDate);
      setData((prevState) => ({ ...prevState, fromdate: maystart }));

      if (may + 1 === currentmonth) {
        setData((prevState) => ({ ...prevState, todate: today }));
      } else {
        if (may + 1 > currentmonth) {
          lastDate = new Date(prevyear, may * 1 + 1, 0);
        } else {
          lastDate = new Date(now.getFullYear(), may * 1 + 1, 0);
        }
        const mayend = convertDateToFieldValue(lastDate);
        setData((prevState) => ({ ...prevState, todate: mayend }));
      }
    } else if (selectedMonth === "june") {
      const june = Math.floor(now.getMonth() / 12 + 5);

      if (june + 1 > currentmonth) {
        firstDate = new Date(prevyear, june * 1, 1);
      } else {
        firstDate = new Date(now.getFullYear(), june * 1, 1);
      }

      const junestart = convertDateToFieldValue(firstDate);
      setData((prevState) => ({ ...prevState, fromdate: junestart }));

      if (june + 1 === currentmonth) {
        setData((prevState) => ({ ...prevState, todate: today }));
      } else {
        if (june + 1 > currentmonth) {
          lastDate = new Date(prevyear, june * 1 + 1, 0);
        } else {
          lastDate = new Date(now.getFullYear(), june * 1 + 1, 0);
        }
        const juneend = convertDateToFieldValue(lastDate);
        setData((prevState) => ({ ...prevState, todate: juneend }));
      }
    } else if (selectedMonth === "july") {
      const july = Math.floor(now.getMonth() / 12 + 6);

      if (july + 1 > currentmonth) {
        firstDate = new Date(prevyear, july * 1, 1);
      } else {
        firstDate = new Date(now.getFullYear(), july * 1, 1);
      }

      const julystart = convertDateToFieldValue(firstDate);
      setData((prevState) => ({ ...prevState, fromdate: julystart }));

      if (july + 1 === currentmonth) {
        setData((prevState) => ({ ...prevState, todate: today }));
      } else {
        if (july + 1 > currentmonth) {
          lastDate = new Date(prevyear, july * 1 + 1, 0);
        } else {
          lastDate = new Date(now.getFullYear(), july * 1 + 1, 0);
        }
        const julyend = convertDateToFieldValue(lastDate);
        setData((prevState) => ({ ...prevState, todate: julyend }));
      }
    } else if (selectedMonth === "aug") {
      const aug = Math.floor(now.getMonth() / 12 + 7);

      if (aug + 1 > currentmonth) {
        firstDate = new Date(prevyear, aug * 1, 1);
      } else {
        firstDate = new Date(now.getFullYear(), aug * 1, 1);
      }

      const augstart = convertDateToFieldValue(firstDate);
      setData((prevState) => ({ ...prevState, fromdate: augstart }));

      if (aug + 1 === currentmonth) {
        setData((prevState) => ({ ...prevState, todate: today }));
      } else {
        if (aug + 1 > currentmonth) {
          lastDate = new Date(prevyear, aug * 1 + 1, 0);
        } else {
          lastDate = new Date(now.getFullYear(), aug * 1 + 1, 0);
        }
        const augend = convertDateToFieldValue(lastDate);
        setData((prevState) => ({ ...prevState, todate: augend }));
      }
    } else if (selectedMonth === "sep") {
      const sep = Math.floor(now.getMonth() / 12 + 8);

      if (sep + 1 > currentmonth) {
        firstDate = new Date(prevyear, sep * 1, 1);
      } else {
        firstDate = new Date(now.getFullYear(), sep * 1, 1);
      }

      const sepstart = convertDateToFieldValue(firstDate);
      setData((prevState) => ({ ...prevState, fromdate: sepstart }));

      if (sep + 1 === currentmonth) {
        setData((prevState) => ({ ...prevState, todate: today }));
      } else {
        if (sep + 1 > currentmonth) {
          lastDate = new Date(prevyear, sep * 1 + 1, 0);
        } else {
          lastDate = new Date(now.getFullYear(), sep * 1 + 1, 0);
        }
        const sepend = convertDateToFieldValue(lastDate);
        setData((prevState) => ({ ...prevState, todate: sepend }));
      }
    } else if (selectedMonth === "oct") {
      const oct = Math.floor(now.getMonth() / 12 + 9);

      if (oct + 1 > currentmonth) {
        firstDate = new Date(prevyear, oct * 1, 1);
      } else {
        firstDate = new Date(now.getFullYear(), oct * 1, 1);
      }

      const octstart = convertDateToFieldValue(firstDate);
      setData((prevState) => ({ ...prevState, fromdate: octstart }));

      if (oct + 1 === currentmonth) {
        setData((prevState) => ({ ...prevState, todate: today }));
      } else {
        if (oct + 1 > currentmonth) {
          lastDate = new Date(prevyear, oct * 1 + 1, 0);
        } else {
          lastDate = new Date(now.getFullYear(), oct * 1 + 1, 0);
        }
        const octend = convertDateToFieldValue(lastDate);
        setData((prevState) => ({ ...prevState, todate: octend }));
      }
    } else if (selectedMonth === "nov") {
      const nov = Math.floor(now.getMonth() / 12 + 10);

      if (nov + 1 > currentmonth) {
        firstDate = new Date(prevyear, nov * 1, 1);
      } else {
        firstDate = new Date(now.getFullYear(), nov * 1, 1);
      }

      const novstart = convertDateToFieldValue(firstDate);
      setData((prevState) => ({ ...prevState, fromdate: novstart }));

      if (nov + 1 === currentmonth) {
        setData((prevState) => ({ ...prevState, todate: today }));
      } else {
        if (nov + 1 > currentmonth) {
          lastDate = new Date(prevyear, nov * 1 + 1, 0);
        } else {
          lastDate = new Date(now.getFullYear(), nov * 1 + 1, 0);
        }
        const novend = convertDateToFieldValue(lastDate);
        setData((prevState) => ({ ...prevState, todate: novend }));
      }
    } else if (selectedMonth === "dec") {
      const dec = Math.floor(now.getMonth() / 12 + 11);

      if (dec + 1 > currentmonth) {
        firstDate = new Date(prevyear, dec * 1, 1);
      } else {
        firstDate = new Date(now.getFullYear(), dec * 1, 1);
      }

      const decstart = convertDateToFieldValue(firstDate);
      setData((prevState) => ({ ...prevState, fromdate: decstart }));

      if (dec + 1 === currentmonth) {
        setData((prevState) => ({ ...prevState, todate: today }));
      } else {
        if (dec + 1 > currentmonth) {
          lastDate = new Date(prevyear, dec * 1 + 1, 0);
        } else {
          lastDate = new Date(now.getFullYear(), dec * 1 + 1, 0);
        }
        const decend = convertDateToFieldValue(lastDate);
        setData((prevState) => ({ ...prevState, todate: decend }));
      }
    }
  };

  return (
    <>
      <div className="date-filter-container d-flex flex-column px-5 py-3 my-2">
        <form
          onSubmit={(event) => {
            event.preventDefault();
            toggleIsUpdating(true);
            const body = JSON.stringify({
              todate: data.todate,
              fromdate: data.fromdate,
            });
            toggleFilterContainer();
            if (api) {
              callAPI("POST", `${api}`, body)
                .then((res) => {
                  setData({ ...res.data });
                  toggleIsUpdating(false);
                })
                .catch((error) => {
                  throwError(new Error(error));
                });
            } else {
              callAPI("POST", `table/${data.table_name}/`, body)
                .then((res) => {
                  setData({ ...res.data });
                  toggleIsUpdating(false);
                })
                .catch((error) => {
                  throwError(new Error(error));
                });
            }
          }}
          className="d-flex align-items-center justify-content-between"
          id="date_form"
        >
          {/*  eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="fromdate">From</label>
          <input
            type="date"
            name="fromdate"
            id="fromdate"
            value={data.fromdate}
            onChange={setFilterDates}
            className="form-control"
          />
          {/*  eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="todate">To</label>
          <input
            type="date"
            name="todate"
            id="todate"
            value={data.todate}
            onChange={setFilterDates}
            className="form-control"
          />
          <button
            id="date_form_button"
            type="submit"
            className="btn-action button ml-4"
          >
            Submit
          </button>
          <div className="btn-group d-flex align-items-content">
            <button
              name="qtdButton"
              type="button"
              className="btn btn-light mx-2"
              onClick={selectMonth}
            >
              QTD
            </button>
            <button
              name="ytdButton"
              type="button"
              className="btn btn-light mr-3"
              onClick={selectMonth}
            >
              YTD
            </button>
          </div>
        </form>
        <div className="btn-group mt-4 d-flex flex-row justify-content-around">
          {months.map((month) => (
            <button
              key={`date-filter-${month}`}
              name={month}
              type="button"
              className="btn btn-light"
              onClick={selectMonth}
            >
              {month.toUpperCase()}
            </button>
          ))}
        </div>
      </div>
    </>
  );
}
