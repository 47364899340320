import React, { useState } from "react";

import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import PublishIcon from "@material-ui/icons/Publish";

import Cloud from "@material-ui/icons/Cloud";
import Description from "@material-ui/icons/Description";
import Error from "@material-ui/icons/Error";
import Menu from "@material-ui/icons/Menu";
import VerifiedUser from "@material-ui/icons/VerifiedUser";

import { makeStyles } from "@material-ui/core/styles";

import upload_file from "../../assets/upload_file.png";

import {
  BLACK,
  GREEN,
  HOME,
  NAVBAR_BLUE,
  ORANGE,
  RED,
  WHITE,
} from "../../utils/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      // width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    // marginLeft: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      // width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    backgroundColor: "#f7f7f7",
    // width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function Sidebar() {
  const classes = useStyles();

  const [open, setOpen] = useState({
    left: false,
  });
  const toggleDrawer = (anchor, isOpen) => (event) => {
    const eventObject = event;
    if (
      eventObject &&
      eventObject.type === "keydown" &&
      (eventObject.key === "Tab" || eventObject.key === "Shift")
    ) {
      return;
    }

    setOpen({ [anchor]: isOpen });
  };

  return (
    <>
      <div className={classes.toolbar} />
      <Divider />

      <SwipeableDrawer
        anchor="left"
        open={open.left}
        onClose={toggleDrawer("left", false)}
        onOpen={toggleDrawer("left", true)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <List style={{ marginTop: "60px" }}>
          <ListItem button>
            <a href={`${HOME}/`} className="d-flex">
              <ListItemIcon className="w-25">
                <Cloud style={{ color: NAVBAR_BLUE, marginRight: "32px" }} />
              </ListItemIcon>

              <font color={BLACK}>
                <span className="w-75" style={{ fontSize: "14px", marginLeft:'-10px' }}>
                  In-Progress Documents
                </span>
              </font>
            </a>
          </ListItem>
          <Divider />

          {/* <ListItem button>
            <a href={`${HOME}/exception`}>
              <ListItemIcon>
                <Error style={{ color: RED, marginRight: "32px" }} />
              </ListItemIcon>

              <font color={BLACK}>
                <span>Claims In Exception</span>
              </font>
            </a>
          </ListItem>
          <Divider />

          <ListItem button>
            <a href={`${HOME}/report`}>
              <ListItemIcon>
                <VerifiedUser style={{ color: GREEN, marginRight: "32px" }} />
              </ListItemIcon>
              <font color={BLACK}>
                <span>Claims Report</span>
              </font>
            </a>
          </ListItem>
          <Divider />

          <ListItem button>
            <a href={`${HOME}/ocr_accuracy`}>
              <ListItemIcon>
                <Description style={{ color: ORANGE, marginRight: "32px" }} />
              </ListItemIcon>
              <font color={BLACK}>
                <span>OCR Accuracy</span>
              </font>
            </a>
          </ListItem> */}
          {/* <Divider /> */}
          <a href={`${HOME}/upload_documents`} className="d-flex ml-1">
            <ListItem
              button
              className="d-flex align-items-center justify-content-center"
            >
              <div className="w-25">
                <ListItemIcon>
                  <img
                    src={upload_file}
                    style={{
                      color: NAVBAR_BLUE,
                      marginLeft: "-6px",
                      height: "23px",
                      width: "21px",
                    }}
                  />
                </ListItemIcon>
              </div>
              <div className="w-75" style={{ marginLeft: "-8px" }}>
                <font color={BLACK}>
                  <span style={{ fontSize: "14px" }}>Upload Document</span>
                </font>
              </div>
            </ListItem>
          </a>
          <Divider />
        </List>
      </SwipeableDrawer>

      <Button
        className="d-flex align-items-center justify-content-center "
        style={{ color: WHITE }}
        onClick={toggleDrawer("left", true)}
      >
        <Menu className="left burger-menu" />
      </Button>
    </>
  );
}
