export default function convertToTitleCase(input) {
  if (input !== undefined) {
    const titleCaseString = input
      .toLowerCase()
      .replace(/([^a-z])([a-z])(?=[a-z]{2})|^([a-z])/g, (_, g1, g2, g3) =>
        typeof g1 === "undefined" ? g3.toUpperCase() : g1 + g2.toUpperCase()
      );

    return titleCaseString;
  }
  return "";
}
