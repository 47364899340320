import React, { useEffect } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";

// import { useAuth } from "../context/authContext";
import Navbar from "../components/navbar/Navbar";
import callAPI from "../utils/callAPI";

export default function ProtectedRoute({ component: Component, ...rest }) {
  // let auth = useAuth();
  const history = useHistory();
  const loggedInUser = localStorage.getItem("user");

  useEffect(() => {
    callAPI("GET", "logged_in/", "").then((res) => {
      if (!res.is_authenticated) {
        localStorage.removeItem("user");
        history.push("/login");
      }
    });
  }, [history]);

  return (
    <>
      <Navbar />
      <Route
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        render={({ match, location }) =>
          loggedInUser !== (undefined || null) ? (
            <main>
              <Component match={match} />
            </main>
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          )
        }
      />
    </>
  );
}
