$white: #FFFFFF
$backgroundGrey: #F7F7F7
$navbarBlue: #007BFF
$borderGrey: #E8E9EC
$bottomBorderGrey: #9e9e9e
$textGrey: #4D4F5C90
$bulletGrey: #EDECFE
$red: #DF426B
$focusBlue: #80BDFF
$green: #4caf50
$highlightGreen: #54f542

$buttonBorderRadius: 5px
$inputBoxRadius: 12px
$boxShadow: 5px 4px 30px rgba(0,0,0,.10)
$inputBoxShadow: 0 0 0 0.2rem rgb(0 123 255 / 25%)
