import React, { lazy, Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import DashboardLoader from "./components/dashboardLoader/DashboardLoader";
import ProtectedRoute from "./HOC/ProtectedRoute";
import LoginTataAIG from "./routes/LoginTG";
// import "./sass/index.sass";
// import { QueryParamProvider } from "use-query-params";

const Login = lazy(() => import("./routes/Login"));

const ClaimsInProgress = lazy(() => import("./routes/ClaimsInProgress"));
const Validate = lazy(() => import("./routes/Validate"));

const ClaimsInException = lazy(() => import("./routes/ClaimsInException"));
const ClaimsReport = lazy(() => import("./routes/ClaimsReport"));
const OcrAccuracy = lazy(() => import("./routes/OcrAccuracy"));
const UploadFile = lazy(() => import("./routes/UploadFile"));

// Rendered when page is loading
const renderLoader = () => (
  <>
    <main>
      <DashboardLoader />
    </main>
  </>
);

export default function Router() {
  return (
    <>
      {/* <QueryParamProvider ReactRouterRoute={Route}> */}
      <Suspense fallback={renderLoader()}>
        <Switch>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/redirect/:token/:documentID">
            <LoginTataAIG />
          </Route>
          <ProtectedRoute exact path="/" component={ClaimsInProgress} />
          <ProtectedRoute path="/validate/:id" component={Validate} />
          {/* <Route exact path="/" component={ClaimsInProgress} /> */}
          <ProtectedRoute
            exact
            path="/exception"
            component={ClaimsInException}
          />
          <ProtectedRoute exact path="/report" component={ClaimsReport} />
          <ProtectedRoute exact path="/ocr_accuracy" component={OcrAccuracy} />
          <ProtectedRoute exact path="/upload_documents" component={UploadFile} />
          <Route render={() => <Redirect to={{ pathname: "/" }} />} />
        </Switch>
      </Suspense>
      {/* </QueryParamProvider> */}
    </>
  );
}
