@import colours
html
    padding: 0
    margin: 0
    background-color: $backgroundGrey
body
    padding: 0
    margin: 0
    background-color: $backgroundGrey

    label
        color: #000000

    nav
        line-height: 45px
        background-color: $navbarBlue
        color: $white

        a
            color: $white

    .burger-menu
        font-size: 28px

    main
        min-height: 92vh
        padding: 5vh 5vw

    .mt-6
        margin-top: 6rem

    .card
        display: flex
        background-color: $white
        border-radius: 20px
        border: none
        padding: 20px 20px
        width: 100%
        box-shadow: $boxShadow

    .custom-card-header
        border-bottom: 1px solid $borderGrey
        font-size: 1rem

    .custom-card-body
        font-size: 50px

    .absolute
        position: absolute

    table
        box-shadow: $boxShadow
        border-radius: 20px
        font-size: 0.9rem
        width: 100%

        thead
            border-bottom: 1px solid rgba(0,0,0,0.05)

        th
            padding: 10px 20px
            border-radius: 0px
            text-align: center
            border-right: 1px solid rgba(0,0,0,0.05)
            // background: rgba(0,0,0,0.05)

        tr:first-child th:first-child
            border-radius: 20px 0 0 0

        tr:first-child th:last-child
            border-radius: 0 20px 0 0

        tbody
            border-radius: 20px

            td
                background-color: $white
                text-align: center
                border-right: 1px solid rgba(0,0,0,0.05)
                border-bottom: 1px solid rgba(0,0,0,0.05)

            td:last-child
                min-width: 130px
                border-right: none

            .expanded
                background-color: #f1f1f1
                td
                    background-color: #f1f1f1

            .cell
                padding: 10px

            tr:last-child td:first-child
                border-radius: 20px

            tr:last-child
                border: none
                height: 50px
                td:last-child
                    background-color: transparent
                    border: none
                    border-radius: 20px

    .dropdown-button
        color: $navbarBlue !important

    select
        background-color: $white
        font-size: 1.1rem
        border: 0
        border-radius: $buttonBorderRadius
    select:focus
        outline: 0
        border-color: #80bdff
        box-shadow: $inputBoxShadow
        border-radius: $buttonBorderRadius

    input
        font-size: 1.1rem
        border: 0
        border-radius: $buttonBorderRadius
        padding: 0 10px
    input:focus
        outline: 0
        border-radius: $buttonBorderRadius
        border-color: #80bdff
        box-shadow: $inputBoxShadow

    textarea:focus
        outline: 0
        border-radius: $buttonBorderRadius
        box-shadow: $inputBoxShadow

    .dropdown-toggle
        background-color: $navbarBlue !important
        border-radius: 20px
        padding: 0 25px
        margin-right: 10px
        height: 25px
        display: flex
        align-items: center

    .pagination-page-count
        width: 60px
.text-grey
    color: $textGrey

.btn
    font-size: 12px
    border-radius: 20px
    box-shadow: 5px 4px 30px rgba(0,0,0,.10)

.btn-page
    background-color: $green
    color: $white
    font-size: 12px
    min-height: 32px
    border-radius: $buttonBorderRadius
    border: 0 !important

.btn-action
    background-color: $navbarBlue
    color: $white
    font-size: 12px
    min-height: 32px
    border-radius: $buttonBorderRadius
    border: 0 !important

.btn-action:hover
    background-color: #0172ce

.btn:focus
    // border: 1px solid rgba(0,123,254,1)
    // color: $navbarBlue
    background-color: $white
    border-color: #80bdff
    outline: 0
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%)

.btn-pagination
    min-width: 80px
    border-radius: $buttonBorderRadius
    background-color: $navbarBlue
    color: $white
    border: 0
.btn-pagination-disabled
    min-width: 80px
    border-radius: $buttonBorderRadius

.date-filter-container
    width: fit-content
    box-shadow: 5px 4px 30px rgba(0,0,0,.10)
    border-radius: 20px

    input
        width: 25%

.upload
    background-color: white
    outline: 2px dashed #e0e0e0
    outline-offset: -10px
    padding: 20px
    width: 50%
    margin: auto
